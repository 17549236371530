import { template as template_49624098876f4e2294fbe4c8190428a6 } from "@ember/template-compiler";
const FKText = template_49624098876f4e2294fbe4c8190428a6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
