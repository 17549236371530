import { template as template_a72cb0712591478aa5dc893774040bae } from "@ember/template-compiler";
const FKLabel = template_a72cb0712591478aa5dc893774040bae(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
