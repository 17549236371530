import { template as template_adc2f96bbb224b6da516a8d4ff350ddd } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_adc2f96bbb224b6da516a8d4ff350ddd(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
